import { getIsMarketingTrackingAllowed } from 'tracking'
import { getParams, utmParams } from 'utils'
import { GIT_BRANCH } from 'config'

export const hydrateFormDataWithContextParams = (formData) => {
  const additionalProps = {
    utm: JSON.stringify(getParams(utmParams)),
    current_path: window.location.pathname,
    ab_test_metadata: JSON.stringify({
      ...(GIT_BRANCH && {
        git_branch: GIT_BRANCH,
      }),
    }),
    tracking_allowed: getIsMarketingTrackingAllowed(),
  }

  return {
    ...formData,
    ...additionalProps,
  }
}
